<template>
  <div class="bottom-search">
    <h3>{{ textData.title }}</h3>
    <h4>
      {{ textData.content }}
    </h4>

    <div class="btn">
      <div @click="pageGo(0)" class="left">
        <p>基金评鉴</p>
        <i class="iconfont iconfenxiang"></i>
      </div>
      <div @click="pageGo(1)" class="right">
        <p>微云办公室</p>
        <i class="iconfont iconfenxiang"></i>
      </div>
    </div>

    <!-- 权限提醒 -->
    <LimitsWarnAlart v-show="warnAlartShow" @warnAlartClose="warnAlartClose" />
  </div>
</template>

<script setup>
import myaxios from "@/http/Myaxios";
import { onMounted, ref, defineAsyncComponent } from "vue";
import upLoadOssApi from "@/utils/UpLoadApi";
import Apis from "@/utils/Apis";
import { ElMessage } from "element-plus";
import router from "@/router";
import store from "@/store";
const LimitsWarnAlart = defineAsyncComponent(() =>
  import("@/components/LimitsWarnAlart")
);

const textData = ref({});
const getTextData = () => {
  let url = "/home/index/seven/list";
  myaxios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200 && res.data.data) {
      textData.value = res.data.data[0];
    }
  });
};

const token = store.state.token || localStorage.getItem("Authorization");
let permissionHas =
  store.state.permissionHas ||
  JSON.parse(localStorage.getItem("permissionHas"));

// 检查用户类型
const urlChange = () => {
  let userAccountType = upLoadOssApi.getUserInfo();

  if (
    userAccountType &&
    userAccountType.accountType &&
    (userAccountType.accountType == "ins" ||
      userAccountType.accountType == "person")
  ) {
    Apis.getCompanyType().then((res) => {
      const index = pageList.value.findIndex(
        (item) => item.title == "基金评鉴"
      );
      let obj = urlReturn(res);
      if (obj) {
        pageList.value[index].url = obj.url;
        pageList.value[index].code = obj.code;
      }
    });
  }
};
const urlReturn = (res) => {
  let type = res;

  if (type && type == "private_invest") {
    return {
      url: "/new-app/fund-evaluation/invest",
      code: "fundEvaluationInvest",
    };
  } else {
    return {
      url: "/new-app/fund-evaluation/equity",
      code: "fundEvaluationEquity",
    };
  }
};

// 是否能够跳转新应用
const newAppPermission = ref(false);
const checkNewApp = () => {
  let url = "/fundTool/auth/checkAuthBefore";
  myaxios.get(url).then((res) => {
    if (res.data.code == 200) {
      newAppPermission.value = true;
    } else {
      newAppPermission.value = false;
    }
  });
};

// 获取权限符
const getPermissionHas = () => {
  if (!permissionHas) {
    upLoadOssApi.getPermissionHas().then((res) => {
      // console.log(res);
      if (res.data.code == 200) {
        permissionHas = res.data.data || [];
      }
    });
  }
};

// 跳转页面
const pageGo = (n) => {
  // 无登录
  if (!token) {
    ElMessage({
      message: "请先登录",
      type: "warning",
    });

    setTimeout(() => {
      router.push("/login/login/index");
    }, 1500);
  } else if (!newAppPermission.value) {
    ElMessage({
      message: "暂无权限",
      type: "warning",
    });
  } else {
    if (!checkArray(pageList.value[n].code)) {
      ElMessage({
        message: "暂无权限",
        type: "warning",
      });

      console.log(78);
    } else {
      newAppJump(n);
    }
  }
};
const newAppJump = (n) => {
  // 新应用跳转
  let url = "/fundTool/auth/checkAuthBefore";
  myaxios.get(url).then((res) => {
    if (res.data.code == 200) {
      let urlJump = `${
        process.env.VUE_APP_new_app_url
      }/jump?k=${Apis.encryptUrl(token)}&to=${pageList.value[n].url}`;
      // let urlJump = `http://127.0.0.1:9090/jump?k=${Apis.encryptUrl(
      //   token
      // )}&to=${pageList.value[n].url}`;

      window.open(urlJump);
    }
  });
};

// 检查权限
const checkArray = (key) => {
  if (permissionHas[0] == "*") {
    return true; // 有权限
  }

  let index = permissionHas.indexOf(key);

  if (index > -1) {
    return true; // 有权限
  } else {
    return false; // 无权限
  }
};

onMounted(() => {
  getTextData();

  if (token) {
    checkNewApp();
    getPermissionHas();

    urlChange();
  }
});

// 电子卡权限
const warnAlartShow = ref(false);
const warnAlartClose = () => {
  warnAlartShow.value = false;
};
const checkCards = () => {
  warnAlartShow.value = true;
};

// 跳转页面
const pageList = ref([
  {
    title: "基金评鉴",
    url: "/new-app/fund-evaluation/invest",
    code: "fundEvaluationInvest",
  },
  {
    title: "微云办公室",
    url: "/new-app/cloud-office?type=myDay",
    code: "cloudOffice",
  },
]);
</script>

<style lang="scss" scoped>
.bottom-search {
  // width: 1200px;
  // width: $screen-vw;
  margin: 0 auto;
  text-align: center;
  padding-top: $box-mt;
  padding-bottom: 120px;
  background-color: #e9f1fd;
  > h3 {
    font-weight: 400;
    font-size: $title-fz;
    color: $title1;
    margin-bottom: 40px;
  }
  > h4 {
    font-weight: 400;
    font-size: 20px;
    color: $title2;
    margin-bottom: 72px;
  }
  > .btn {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    > div {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 240px;
      height: 54px;
      border-radius: 27px;
      color: #fff;
      cursor: pointer;
      user-select: none;
      transition: 0.3s;
      &:hover {
        @include btn-c;
      }

      > p {
        font-size: $font-size1;
        margin-right: 30px;
      }
      > i {
        font-size: 25px;
        font-weight: 100;
      }
    }
    > .left {
      background-color: $primary-red;
      margin-right: 66px;
    }
    > .right {
      background-color: $primary-blue;
      color: #fff;
    }
  }
}
</style>
